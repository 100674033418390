<template>
  <div class="box">
    <ResetDeletedVocabularyDialog
      :visible="resetDeletedVocabularyDialog"
      :width="isPhone ? '90%' : '30%'"
      @cancel="
        () => {
          hasDeletedVocabulary = false;
          $router.back(-1);
        }
      "
      @continue="
        () => {
          resetDeletedVocabularyDialog = false;
        }
      "
    />
    <div class="flashcardHeader d-flex">
      <div class="pl-3 return">
        <div
          v-if="!showNotes"
          @click="goBack"
          style="color: var(--themeColor); cursor: pointer"
        >
          <el-tooltip content="Go Back" placement="bottom">
            <span type="text">
              <i class="fas fa-chevron-left"></i>
            </span>
          </el-tooltip>
        </div>
        <div
          v-else
          @click="showNotes = false"
          style="color: var(--themeColor); cursor: pointer"
        >
          <el-tooltip content="Go Back" placement="bottom">
            <span type="text">
              <i class="fas fa-chevron-left"></i>
            </span>
          </el-tooltip>
        </div>
      </div>
      <div class="headerTitle">
        <span style="padding-right: 25px; font-size:18px;line-height:40px">
          {{ title }}
        </span>
      </div>
    </div>
    <template v-if="showNotes">
      <NotesList :mnemonics="mnemonics" />
    </template>
    <template v-else>
      <div class="card-content">
        <div v-if="isLoading" style="padding:0 15px">
          Loading...
        </div>
        <div v-else-if="vocabs.length > 0">
          <div
            v-for="(word, index) in vocabs"
            :key="index"
            class="word-card-box"
          >
            <div class="wordCard">
              <span
                class="warning-icon"
                v-show="edit"
                @click="removeStar(word.vocab_front_id, index)"
              >
                <i class="fa fa-trash-alt"></i>
                <el-divider direction="vertical"></el-divider>
              </span>
              <el-tooltip
                class="item"
                effect="dark"
                content="Start from here"
                placement="top"
              >
                <div class="word pointer" @click="showWord(word)">
                  <span>{{ word.word }}</span>
                </div>
              </el-tooltip>
              <!-- <div class="word pointer">
                <span>{{ word.word }}</span>
              </div> -->
              <div class="voice">
                <el-divider direction="vertical"></el-divider>
                <span class="pointer" @click="speakWord(word.word, 'en-US')"
                  >US&nbsp;<i class="fas fa-play-circle"></i
                ></span>
                <el-divider direction="vertical"></el-divider>
                <span class="pointer" @click="speakWord(word.word, 'en-GB')"
                  >UK&nbsp;<i class="fas fa-play-circle"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div v-else style="padding:0 15px">
          No words found, please go back.
        </div>
        <!-- <div class="wordCard addNew" v-show="edit">
          Add New<span><i class="el-icon-plus avatar-uploader-icon"></i></span>
        </div> -->
      </div>
    </template>
    <div class="levelButtons pointer" v-if="vocabs.length > 0">
      <div class="d-flex flex-buttons">
        <div
          style="width: 100%; color: #5bc0de;"
          class="btnStyle"
          @click="reorder"
        >
          <div style="background-color: #5bc0de;" class="btnBar"></div>
          <div><i class="fas fa-recycle"></i></div>
          <div class="btnClick">Reorder</div>
        </div>
        <div
          style="width: 100%; color: var(--themeColor);"
          class="btnStyle"
          @click="review"
        >
          <div style="background-color: var(--themeColor);" class="btnBar"></div>
          <div><i class="fas fa-play-circle"></i></div>
          <div class="btnClick">START</div>
        </div>
      </div>
    </div>
    <el-drawer title="Reorder" :visible.sync="reorderShow" direction="ttb">
      <div style="padding:0 15px">
        <div @click="setOrder('1')">
          <el-radio v-model="reorderVal" label="1">Random</el-radio>
        </div>
        <div @click="setOrder('2')">
          <el-radio v-model="reorderVal" label="2">A - Z</el-radio>
        </div>
        <div @click="setOrder('3')">
          <el-radio v-model="reorderVal" label="3">Z - A</el-radio>
        </div>
      </div>
      <!-- <div class="text-center" style="margin-top:10px">
        <el-button type="success" @click="setOrder">Save</el-button>
      </div> -->
    </el-drawer>
    <el-drawer
      v-if="word !== null"
      :visible.sync="wordShow"
      direction="rtl"
      size="100%"
    >
      <template slot="title">
        <div style="display: flex; align-items: center;">
          <h1 style="color: var(--themeColor)">{{ word.word }}</h1>
          <el-button
            type="primary"
            size="mini"
            style="color: #fff;font-size:16px; margin-left:12px"
            @click.stop="speakWord(word.word, 'en-US')"
            >US&nbsp;<i class="fas fa-volume-up"></i
          ></el-button>
          <el-button
            type="primary"
            size="mini"
            style="font-size:16px"
            @click.stop="speakWord(word.word, 'en-GB')"
            >UK&nbsp;<i class="fas fa-volume-up"></i
          ></el-button>
        </div>
      </template>
      <div style="padding:0 15px">
        <div class="wordDetail">
          <el-divider content-position="left" style="font-size:20px">
            <i class="fas fa-book book"></i>
            {{ $t("flashCards.Memorization technique") }}
          </el-divider>
          <div
            class="mt-3 mb-3 note-card note-stared"
            v-if="
              word.memorization_technique !== '' &&
                word.memorization_technique !== null
            "
          >
            <div class="note-content pt-2 pb-2">
              <div>
                {{ word.memorization_technique }}
              </div>
              <div>
                <small class="starIsSelected">
                  <b>Ivy Way{{ $t("flashCards.Memorization technique") }}</b>
                </small>
              </div>
            </div>
            <div><el-divider direction="vertical"></el-divider></div>
            <div class="note-star pt-2 pb-2" @click.stop="saveWordLike(word)">
              <div class="thumbs" v-show="word.liked_by_me === 1">
                <i class="fas fa-thumbs-up starIsSelected"></i>
              </div>
              <div class="thumbs" v-show="word.liked_by_me === 0">
                <i class="far fa-thumbs-up"></i>
              </div>
              {{ word.star }}
            </div>
          </div>
          <div
            class="mt-3 mb-3 note-card note-stared"
            v-if="myMnemonic.id && myMnemonic.id > 0"
          >
            <div
              class="note-star pt-2 pb-2"
              style="font-size:16px"
              @click.stop="showEditNote = true"
            >
              <i class="fas fa-edit"></i>
            </div>
            <div><el-divider direction="vertical"></el-divider></div>
            <div class="note-content pt-2 pb-2">
              <div>
                {{ myMnemonic.mnemonic }}
              </div>
              <div>
                <small class="starIsSelected">
                  <b>{{ $t("flashCards.My technique") }}</b>
                </small>
              </div>
            </div>
            <div><el-divider direction="vertical"></el-divider></div>
            <div class="note-star pt-2 pb-2" @click.stop="saveLike(myMnemonic)">
              <div class="thumbs" v-show="myMnemonic.liked_by_me === 1">
                <i class="fas fa-thumbs-up starIsSelected"></i>
              </div>
              <div class="thumbs" v-show="myMnemonic.liked_by_me === 0">
                <i class="far fa-thumbs-up"></i>
              </div>
              {{ myMnemonic.likes }}
            </div>
          </div>
          <div v-else>
            <div v-if="mnemonics.length > 0">
              <div
                class="mt-3 mb-2 note-card note-stared"
                v-for="(item, index) in mnemonics.slice(0, 1)"
                :key="index"
              >
                <div class="note-content pt-2 pb-2">
                  <div>
                    {{ item.mnemonic }}
                  </div>
                  <div>
                    <small
                      >Author: {{ item.user.first_name }}
                      {{ item.user.last_name }}</small
                    >
                  </div>
                </div>
                <div><el-divider direction="vertical"></el-divider></div>
                <div class="note-star pt-2 pb-2" @click.stop="saveLike(item)">
                  <div class="thumbs" v-show="item.liked_by_me === 1">
                    <i class="fas fa-thumbs-up starIsSelected"></i>
                  </div>
                  <div class="thumbs" v-show="item.liked_by_me === 0">
                    <i class="far fa-thumbs-up"></i>
                  </div>
                  {{ item.likes + item.super_likes }}
                </div>
              </div>
            </div>
            <div class="text-center" style="font-size:16px;margin-top:20px">
              <el-button size="small" @click.stop="showEditNote = true">
                <i class="fas fa-plus"></i>
                {{ $t("flashCards.Add new technique") }}
              </el-button>
            </div>
          </div>
          <template v-if="mnemonics.length > 0">
            <el-collapse-transition>
              <div class="openModal" v-show="openNote">
                <div
                  class="mt-3 mb-2 note-card note-stared"
                  v-for="(item, index) in mnemonics.slice(0, 3)"
                  :key="index"
                >
                  <div class="note-content pt-2 pb-2">
                    <div>
                      {{ item.mnemonic }}
                    </div>
                    <div>
                      <small
                        >Author: {{ item.user.first_name }}
                        {{ item.user.last_name }}</small
                      >
                    </div>
                  </div>
                  <div><el-divider direction="vertical"></el-divider></div>
                  <div class="note-star pt-2 pb-2" @click.stop="saveLike(item)">
                    <div class="thumbs" v-show="item.liked_by_me === 1">
                      <i class="fas fa-thumbs-up starIsSelected"></i>
                    </div>
                    <div class="thumbs" v-show="item.liked_by_me === 0">
                      <i class="far fa-thumbs-up"></i>
                    </div>
                    {{ item.likes + item.super_likes }}
                  </div>
                </div>
                <div class="text-right">
                  <el-button type="text" size="small" @click.stop="viewNotes"
                    >More <i class="fas fa-chevron-right"></i
                  ></el-button>
                </div>
              </div>
            </el-collapse-transition>
            <div class="demo-block-control" @click.stop="openNote = !openNote">
              <div v-show="!openNote">
                <i class="fas fa-chevron-down"></i>
              </div>
              <div v-show="openNote">
                <i class="fas fa-chevron-up"></i>
              </div>
            </div>
          </template>
          <div class="" style="margin: 40px 0 50px;">
            <el-divider content-position="left">
              <i class="fas fa-book book"></i> {{ $t("flashCards.Meaning") }}
            </el-divider>
            <div class="mt-3 mb-3 note-card note-stared">
              <div class="note-content pt-2 pb-2">
                <div>
                  ( {{ word.pos }} )
                  {{ word.meaning.en }}
                  {{ word.meaning.tw }}
                </div>
              </div>
              <!-- <div><el-divider direction="vertical"></el-divider></div>
              <div
                class="note-star pt-2 pb-2"
                style="font-size:16px"
                @click.stop="showEditNote = true"
              >
                <i class="fas fa-edit"></i>
              </div> -->
            </div>
            <!-- <el-collapse-transition>
              <div class="openModal" v-show="openWord"></div>
            </el-collapse-transition>
            <div
              class="demo-block-control"
              @click.stop="openWord = !openWord"
            >
              <div v-show="!openWord">
                <i class="fas fa-chevron-down"></i>
              </div>
              <div v-show="openWord">
                <i class="fas fa-chevron-up"></i>
              </div>
            </div> -->
          </div>
          <el-divider content-position="left">
            <i class="fas fa-book book"></i> {{ $t("flashCards.Sentence") }}
          </el-divider>
          <div>
            {{ word.sentence }}
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog
      :title="$t('flashCards.My technique')"
      :visible.sync="showEditNote"
      width="95%"
    >
      <div>
        <el-input type="textarea" :rows="5" v-model="myMnemonic.mnemonic">
        </el-input>
        <el-checkbox v-model="is_public" style="margin-top:10px">
          {{ $t("flashCards.Share with other students") }}
        </el-checkbox>
        &nbsp;
        <el-tooltip
          class="item"
          effect="dark"
          :content="
            $t(
              'flashCards.our memorization technique is public to help other students remember'
            )
          "
          placement="top-start"
        >
          <span><i class="fas fa-question-circle"></i></span>
        </el-tooltip>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          size="small"
          @click="deleteMnemonic(myMnemonic.id)"
          v-if="myMnemonic.id && myMnemonic.id > 0"
        >
          {{ $t("courses.delete") }}
        </el-button>
        <el-button type="primary" size="small" @click="saveMnemonic">
          {{ $t("courses.save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import FlashCards from "@/apis/flashcards";
import _ from "lodash";
import ResetDeletedVocabularyDialog from "@/components/flashCard/ResetDeletedVocabularyDialog";
import NotesList from "@/views/flashCards/components/NotesList.vue";

export default {
  metaInfo() {
    return {
      title: this.title + " - " + this.CompanyName
    };
  },
  components: {
    ResetDeletedVocabularyDialog,
    NotesList
  },
  mixins: [],
  data() {
    return {
      title: "",
      edit: false,
      reorderShow: false,
      wordShow: false,
      reorderVal: "2",
      word: null,
      isLoading: true,
      list: {
        title: "",
        intro: "",
        vocabs: []
      },
      vocabs: [],
      utterThis: new SpeechSynthesisUtterance(""),
      synth: window.speechSynthesis,
      resetDeletedVocabularyDialog: false,
      hasDeletedVocabulary: false,
      showEditNote: false,
      myMnemonic: {
        mnemonic: "",
        mnemonic_img_url: "",
        is_public: 0
      },
      openNote: false,
      is_public: false,
      mnemonics: [],
      showNotes: false
    };
  },
  async created() {
    if (this.$route.query.selectedList) {
      await this.$store.dispatch("flashcards/getVocabs", {
        vocab_lists: this.$route.query.selectedList.split(",")
      });
      this.title = this.$route.params.title;
    }

    this.vocabs = _.cloneDeep(
      this.$store.getters["flashcards/getCurrentSelectedVocabs"]
    );
    this.isLoading = false;
  },
  mounted() {
    const main = document.getElementsByClassName("el-main");
    main[0].style.padding = "0";
  },
  beforeDestroy() {
    if (window.innerWidth > 992) {
      // 離開此頁面後將 padding 恢復原狀
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "100px 0px 0px 0px";
    } else {
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "60px 0px 0px 0px";
    }
  },
  beforeRouteLeave: function(to, from, next) {
    if (this.hasDeletedVocabulary) {
      this.resetDeletedVocabularyDialog = true;
      next(false);
    } else {
      next();
    }
  },
  computed: {
    ...mapState("user", ["profile"]),
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    }
  },
  watch: {
    is_public(val) {
      if (val) {
        this.myMnemonic.is_public = 1;
      } else {
        this.myMnemonic.is_public = 0;
      }
    }
  },
  methods: {
    goBack() {
      this.$router.back(-1);
    },
    reorder() {
      this.reorderShow = true;
    },
    review() {
      this.$store.commit("flashcards/setCurrentSelectedVocabs", this.vocabs);
      this.$router.push({
        name: "MemorizeList",
        params: {
          title: this.title
        },
        query: {
          from: "list"
        }
      });
    },
    viewNotes() {
      this.showNotes = true;
      this.wordShow = false;
    },
    rotateArray(arr, index) {
      // 检查输入是否有效
      if (
        !Array.isArray(arr) ||
        typeof index !== "number" ||
        index < 0 ||
        index >= arr.length
      ) {
        throw new Error("Invalid input");
      }

      // 使用 slice 方法获取从 index 到数组末尾的元素
      const part1 = arr.slice(index);

      // 使用 slice 方法获取从数组开始到 index 的元素
      const part2 = arr.slice(0, index);

      // 连接两个部分
      return part1.concat(part2);
    },

    showWord(word) {
      console.log(word);
      let indexV = 0;
      this.vocabs.forEach((wordV, index) => {
        if (
          wordV.word == word.word &&
          wordV.vocab_front_id == word.vocab_front_id
        ) {
          indexV = index;
        }
      });
      // const myArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      // const newStartIndex = 3; // 假设我们想要从下标3开始重新排序
      const newVocabs = this.rotateArray(this.vocabs, indexV);
      console.log(newVocabs); // 输出: [1, 2, 3, 7, 8, 9, 4, 5, 6]
      this.$store.commit("flashcards/setCurrentSelectedVocabs", newVocabs);
      this.$router.push({
        name: "MemorizeList",
        params: {
          title: this.title
        },
        query: {
          from: "list"
        }
      });
    },
    speakWord(word, lang) {
      this.utterThis.lang = lang;
      this.utterThis.text = word;
      this.synth.speak(this.utterThis);
    },
    setOrder(val) {
      this.reorderVal = val;
      console.log(val);
      if (this.reorderVal === "1") {
        this.vocabs = _.shuffle(this.vocabs);
      }
      if (this.reorderVal === "2") {
        this.vocabs = _.orderBy(this.vocabs, ["word", "asc"]);
      }
      if (this.reorderVal === "3") {
        this.vocabs = _.orderBy(this.vocabs, ["word"], ["desc"]);
      }
      this.reorderShow = false;
    },
    async removeStar(word_id, index) {
      // const res = await VocabList.deleteVocabFromList(
      //   this.$route.query.id,
      //   word_id
      // );
      this.hasDeletedVocabulary = true;
      this.vocabs.splice(index, 1);
    },
    async getMnemonics() {
      const res = await FlashCards.getMnemonics({
        vocab_front_id: this.word.vocab_front_id
      });
      this.mnemonics = res.mnemonics;
      this.myMnemonic = {
        mnemonic: "",
        mnemonic_img_url: "",
        is_public: 0
      };
      let myIndex = null;
      this.mnemonics.forEach((val, index) => {
        if (val.user_id === this.profile.id) {
          this.myMnemonic = val;
          myIndex = index;
          if (this.myMnemonic.is_public === 1) {
            this.is_public = true;
          } else {
            this.is_public = false;
          }
        }
      });
      if (myIndex !== null) {
        this.mnemonics.splice(myIndex, 1);
      }
      this.mnemonics = _.orderBy(
        this.mnemonics,
        ["liked_by_me", "likes"],
        ["desc", "desc"]
      );
    },
    async getliked(word) {
      const liked = await FlashCards.likedByme(word.vocab_back_id);
      this.word = { ...word };
      this.word["liked_by_me"] = null;
      this.word["liked_by_me"] = liked.liked_by_me;
      this.wordShow = true;
      this.getMnemonics(word);
    },
    saveMnemonic() {
      if (this.myMnemonic && this.myMnemonic.id > 0) {
        this.updateMnemonic(this.myMnemonic.id);
      } else {
        this.createMnemonic();
      }
      this.showEditNote = false;
    },
    async deleteMnemonic(id) {
      await FlashCards.deleteMnemonic(id);
      this.getMnemonics();
      this.showEditNote = false;
    },
    async updateMnemonic(id) {
      const res = await FlashCards.updateMnemonic(id, {
        mnemonic: this.myMnemonic.mnemonic,
        mnemonic_img_url: this.myMnemonic.mnemonic_img_url,
        is_public: this.myMnemonic.is_public
      });
      this.getMnemonics();
    },
    async createMnemonic() {
      const res = await FlashCards.createMnemonic({
        vocab_front_id: this.word.vocab_front_id,
        mnemonic: this.myMnemonic.mnemonic,
        mnemonic_img_url: this.myMnemonic.mnemonic_img_url,
        is_public: this.myMnemonic.is_public
      });
      this.getMnemonics();
    },
    async saveWordLike(item) {
      if (item.liked_by_me === 1) {
        item.liked_by_me = 0;
        if (item.star > 0) {
          item.star = item.star - 1;
        }
      } else {
        item.liked_by_me = 1;
        item.star = item.star + 1;
      }
      await FlashCards.saveWordLike(item.vocab_back_id);
    },
    async saveLike(item) {
      if (item.liked_by_me === 1) {
        item.liked_by_me = 0;
        if (item.likes > 0) {
          item.likes = item.likes - 1;
        }
      } else {
        item.liked_by_me = 1;
        item.likes = item.likes + 1;
      }
      await FlashCards.saveLike(item.id);
      // this.getMnemonics();
    }
  }
};
</script>

<style scoped>
.box {
  height: 100vh;
  display: grid;
  grid-template-rows: 55px auto 83px;
}
.card-content {
  overflow: auto;
}
.flashcardHeader {
  height: 55px;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: black;
  font-size: 28px;
}
.return:hover {
  opacity: 60%;
}
.content {
  padding-top: 15px;
}
.headerTitle {
  text-align: center;
  width: 100%;
  font-weight: 600;
}

.word-card-box {
  color: var(--themeColor);
}

.word-card-box :hover {
  background: var(--themeColor);
  color: white;
}
.wordCard {
  border-bottom: 1px solid #d7dae2;
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
}
.wordCard.addNew {
  font-size: 14px;
  font-weight: 500;
}
.wordCard .word {
  font-size: 16px;
  font-weight: 500;
}

.word {
  flex-grow: 1;
}

.levelButtons {
  position: fixed;
  background-color: white;
  width: 100%;
  bottom: 0;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.flex-buttons {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.btnBar {
  height: 10px;
}

.btnStyle {
  font-size: 26px;
  border: 1px solid #e0e0e0;
  padding-bottom: 12px;
  font-weight: 600;
}
.btnStyle:hover {
  opacity: 0.5;
}

.btnClick {
  font-size: 18px;
  line-height: 20px;
  padding-top: 0;
  cursor: pointer;
}

.drawerItem {
  cursor: pointer;
}

.drawerItem:hover {
  background-color: #e8f5e9;
}
.voice span {
  /* color: var(--themeColor); */
}
::v-deep #el-drawer__title {
  margin-bottom: 20px;
  font-size: 20px;
}
::v-deep #el-drawer__title span {
  font-weight: 500;
}

.wordDetail {
  font-size: 14px;
  font-weight: 500;
}

.exButton {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #e8f5e9;
  color: var(--themeColor);
  padding-left: 4px;
  padding-right: 4px;
}

.pointer {
  cursor: pointer;
}
.wordDetail[data-v-1f5839cc] {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 500;
}
.note-card {
  font-size: 12px;
  padding: 0 10px;
  display: flex;
  background-color: #ecf6f0;
  color: var(--themeColor);
  border-radius: 4px;
}
.note-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.note-content small {
  font-weight: normal;
  margin-top: 10px;
  display: inline-block;
}
::v-deep .el-divider__text.is-left {
  left: 0;
  font-size: 20px;
}
::v-deep .el-divider__text {
  padding-left: 8px;
  color: var(--themeColor);
}
::v-deep .el-dialog__wrapper {
  width: 95%;
}
::v-deep .el-divider--vertical {
  height: 100%;
  margin: 0 10px;
}
::v-deep .el-dialog__body {
  padding: 10px 20px 10px 20px;
}
::v-deep .el-dialog__title {
  font-weight: 500;
}

::v-deep .el-drawer__body {
  overflow: auto;
}

.demo-block-control {
  margin-top: 10px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  color: var(--themeColor);
  cursor: pointer;
  position: relative;
}
.openModal {
  background: #f9f9f9;
  margin: 10px -20px 0px;
  padding: 10px 20px 5px;
}
.note-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.note-card {
  font-size: 12px;
  padding: 0 10px;
  display: flex;
  background-color: #ecf6f0;
  color: var(--themeColor);
  border-radius: 4px;
}
.note-star {
  text-align: center;
  margin: auto 0;
  width: 30px;
  font-size: 12px;
}
.note-star .thumbs {
  font-size: 20px;
  display: block;
  width: 30px;
}
.starIsSelected {
  color: orange;
  cursor: pointer;
}
::v-deep #el-drawer__title {
  font-size: 30px;
}
</style>
